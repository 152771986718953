import * as React from 'react';
import { CordProvider } from '@cord-sdk/react';
import { useAPIFetch } from 'src/client/hooks/useAPIFetch';
import { CanvasWindow } from 'src/client/components/CanvasWindow';
import { CanvasAndCommentsProvider } from 'src/client/CanvasAndCommentsContext';

function useCordToken(): [string | undefined, string | undefined] {
  const data = useAPIFetch<
    { userID: string; token: string } | { redirect: string }
  >('/token');

  if (!data) {
    return [undefined, undefined];
  } else if ('redirect' in data) {
    const redirectUrl = new URL(data.redirect);
    redirectUrl.searchParams.set('state', window.location.href);
    window.location.href = redirectUrl.toString();
    return [undefined, undefined];
  } else {
    return [data.token, data.userID];
  }
}

export default function App() {
  const [cordToken] = useCordToken();

  return (
    <CordProvider
      clientAuthToken={cordToken}
      onInitError={(e) =>
        console.error('Error when initializing Cord sdk: ', e)
      }
    >
      {cordToken && (
        <CanvasAndCommentsProvider>
          <CanvasWindow />
        </CanvasAndCommentsProvider>
      )}
    </CordProvider>
  );
}
