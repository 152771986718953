import * as React from 'react';
export function CommentIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.94118 17.5H9C13.6944 17.5 17.5 13.6944 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9V16.0588C0.5 16.8548 1.14524 17.5 1.94118 17.5Z"
        fill="white"
      />
      <path
        d="M1.94118 17.5H9C13.6944 17.5 17.5 13.6944 17.5 9C17.5 4.30558 13.6944 0.5 9 0.5C4.30558 0.5 0.5 4.30558 0.5 9V16.0588C0.5 16.8548 1.14524 17.5 1.94118 17.5Z"
        stroke="white"
      />
    </svg>
  );
}
