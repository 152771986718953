import * as React from 'react';
import { Stage, Layer } from 'react-konva';
import type { KonvaEventObject } from 'konva/lib/Node';
import cx from 'classnames';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { LiveCursors } from '@cord-sdk/react';
import type { Location } from '@cord-sdk/types';
import type { ThreadMetadata } from 'src/client/canvasUtils/common';
import { getStageData } from 'src/client/canvasUtils/common';
import { createNewPin } from 'src/client/canvasUtils/pin';
import { CommentIcon } from 'src/client/components/CommentIcon';
import { ZoomControls } from 'src/client/components/ZoomControls';
import { CanvasComments } from 'src/client/components/CanvasComments';
import { GROUP_ID, THREADS_LOCATION } from 'src/client/consts/consts';
import type { BasicCanvasElProps } from 'src/client/CanvasAndCommentsContext';
import { CanvasAndCommentsContext } from 'src/client/CanvasAndCommentsContext';
import type { KonvaComponentTypes } from 'src/client/components/CanvasElements';
import { CanvasElement } from 'src/client/components/CanvasElements';
import { CanvasCreateElementModal } from 'src/client/components/CanvasCreateElementModal';

export default function Canvas() {
  const {
    canvasStageRef,
    canvasContainerRef,
    openThread,
    setOpenThread,
    inThreadCreationMode,
    setInThreadCreationMode,
    removeThreadIfEmpty,
    addThread,
    setIsPanningCanvas,
    isPanningCanvas,
    recomputePinPositions,
    remoteUpdateElementPosition,
    zoomAndCenter,
    scale,
    canvasElements,
    createNewElement,
    deleteCanvasElement,
    localUpdateElementPosition,
  } = useContext(CanvasAndCommentsContext)!;

  const timeoutPanningRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const canvasAndCordContainerRef = useRef<HTMLDivElement>(null);
  const [selectedShapeName, setSelectedShape] = React.useState<string | null>(
    null,
  );

  const [elementTypeToCreate, setElementTypeToCreate] = useState<
    KonvaComponentTypes | undefined
  >(undefined);
  const [elementToCreate, setElementToCreate] = useState<any>(undefined);

  useEffect(() => {
    if (elementToCreate) {
      setElementTypeToCreate(undefined);
    }
  }, [elementToCreate]);

  const updateCanvasSize = useCallback(() => {
    const stage = canvasStageRef.current;
    if (!canvasContainerRef.current || !stage) {
      return;
    }
    stage.size({
      width: canvasContainerRef.current.clientWidth,
      height: canvasContainerRef.current.clientHeight,
    });
  }, [canvasContainerRef, canvasStageRef]);

  useEffect(() => {
    // Sets the canvas stage initially
    updateCanvasSize();
  }, [updateCanvasSize]);

  useEffect(() => {
    // When window resizes, resize canvas
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [updateCanvasSize]);

  useEffect(() => {
    const preventBrowserNavigation = (e: WheelEvent) => {
      if (e.target instanceof HTMLCanvasElement) {
        e.preventDefault();
      }
    };
    window.addEventListener('wheel', preventBrowserNavigation, {
      passive: false,
    });
    return window.removeEventListener('wheel', preventBrowserNavigation);
  }, []);

  const onStageClick = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      removeThreadIfEmpty(openThread);
      setOpenThread(null);
      const elementName = e.target.attrs.name;

      if (!canvasStageRef.current) {
        return;
      }
      const pointerPosition = e.target.getRelativePointerPosition();

      const relativeX = pointerPosition?.x ?? 0;
      const relativeY = pointerPosition?.y ?? 0;

      const elementPosition = e.target.getPosition();

      const { stageX, stageY, scale, stagePointerPosition } = getStageData(
        canvasStageRef.current,
      );

      if (elementToCreate) {
        createNewElement({
          ...elementToCreate,
          x: stagePointerPosition.x / scale - stageX / scale,
          y: stagePointerPosition.y / scale - stageY / scale,
        });
        setElementToCreate(undefined);
        return;
      }

      if (!inThreadCreationMode) {
        return;
      }

      e.evt.preventDefault();
      e.evt.stopPropagation();

      e.target.stopDrag();

      let x, y: number;
      if (elementName === 'stage') {
        x = stagePointerPosition.x;
        y = stagePointerPosition.y;
      } else {
        x = stageX + (elementPosition.x + relativeX) * scale;
        y = stageY + (elementPosition.y + relativeY) * scale;
      }

      const threadMetadata: ThreadMetadata = {
        relativeX,
        relativeY,
        elementName,
      };

      const pin = createNewPin({
        threadMetadata,
        x,
        y,
      });

      addThread(pin.threadID, pin);

      setOpenThread({ threadID: pin.threadID, empty: true });
      setInThreadCreationMode(false);
    },
    [
      addThread,
      canvasStageRef,
      createNewElement,
      elementToCreate,
      inThreadCreationMode,
      openThread,
      removeThreadIfEmpty,
      setInThreadCreationMode,
      setOpenThread,
    ],
  );

  const onEscapeOrDeletePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setInThreadCreationMode(false);
        setElementToCreate(undefined);
        setElementTypeToCreate(undefined);
        removeThreadIfEmpty(openThread);
        setOpenThread(null);
      }

      if (event.key === 'Backspace' || event.key === 'Delete') {
        if (selectedShapeName) {
          deleteCanvasElement(selectedShapeName);
        }
      }
    },
    [
      deleteCanvasElement,
      openThread,
      removeThreadIfEmpty,
      selectedShapeName,
      setInThreadCreationMode,
      setOpenThread,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEscapeOrDeletePress);
    return () => window.removeEventListener('keydown', onEscapeOrDeletePress);
  }, [onEscapeOrDeletePress]);

  const onStageWheel = useCallback(
    ({ evt }: KonvaEventObject<WheelEvent>) => {
      evt.preventDefault();

      if (!isPanningCanvas) {
        setIsPanningCanvas(true);
      }
      // Improving the panning experience over canvas
      if (timeoutPanningRef.current !== null) {
        clearTimeout(timeoutPanningRef.current);
      }
      timeoutPanningRef.current = setTimeout(
        () => setIsPanningCanvas(false),
        300,
      );

      const isPinchToZoom = evt.ctrlKey;
      const stage = canvasStageRef.current;
      if (!stage) {
        return;
      }
      if (isPinchToZoom) {
        // https://konvajs.org/docs/sandbox/Zooming_Relative_To_Pointer.html
        const scaleBy = 1.03;
        let direction = evt.deltaY > 0 ? 1 : -1;
        // When we zoom on trackpads,
        // e.evt.ctrlKey is true so in that case lets revert direction.
        if (evt.ctrlKey) {
          direction = -direction;
        }
        const newScale = direction > 0 ? scale * scaleBy : scale / scaleBy;
        const pointer = stage.getPointerPosition() ?? { x: 0, y: 0 };
        const mousePointTo = {
          x: (pointer.x - stage.x()) / scale,
          y: (pointer.y - stage.y()) / scale,
        };

        const center = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };

        zoomAndCenter({ newScale, center });
      } else {
        // Just panning the canvas
        const { deltaX, deltaY } = evt;
        const { x, y } = stage.getPosition();
        stage.position({ x: x - deltaX, y: y - deltaY });
      }
      recomputePinPositions();
    },
    [
      isPanningCanvas,
      setIsPanningCanvas,
      canvasStageRef,
      recomputePinPositions,
      scale,
      zoomAndCenter,
    ],
  );

  const canvasElementComponents = React.useMemo(
    () =>
      canvasElements.map((el: BasicCanvasElProps) => (
        <CanvasElement
          {...el}
          isSelected={el.name === selectedShapeName}
          onSelect={() => {
            if (!inThreadCreationMode) {
              setSelectedShape(el.name);
            }
          }}
          onChange={(newAttrs) => {
            // some attributes we can't store in our metadata e.g. event handlers,
            // arrays
            const necessaryOnes = {
              height: newAttrs.height,
              width: newAttrs.width,
              name: el.name,
              scale: newAttrs.scale,
              x: newAttrs.x,
              y: newAttrs.y,
              rotation: newAttrs.rotation,
            };
            localUpdateElementPosition(necessaryOnes);
            remoteUpdateElementPosition(necessaryOnes);
          }}
          key={el.name}
          draggable={!inThreadCreationMode}
          onMouseEnter={(e: KonvaEventObject<MouseEvent>) => {
            const container = e.target.getStage()?.container();
            if (container) {
              container.style.cursor = 'grab';
            }
          }}
          onMouseLeave={(e: KonvaEventObject<MouseEvent>) => {
            const container = e.target.getStage()?.container();
            if (container) {
              container.style.cursor = 'default';
            }
          }}
        />
      )),
    [
      canvasElements,
      inThreadCreationMode,
      localUpdateElementPosition,
      remoteUpdateElementPosition,
      selectedShapeName,
    ],
  );

  const checkDeselect = (
    e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
  ) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedShape(null);
    }
  };

  return (
    <div className="canvasAndCordContainer" ref={canvasAndCordContainerRef}>
      <div className="canvasContainer" ref={canvasContainerRef}>
        <Stage
          id="stage"
          ref={canvasStageRef}
          className={cx({
            ['commentingModeCursor']: inThreadCreationMode,
            ['addingModeCursor']: elementToCreate,
          })}
          name="stage"
          onClick={onStageClick}
          onWheel={onStageWheel}
          width={window.innerWidth}
          height={window.innerHeight}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
        >
          <Layer>{...canvasElementComponents}</Layer>
        </Stage>
        <LiveCursors
          groupId={GROUP_ID}
          location={THREADS_LOCATION}
          boundingElementRef={canvasContainerRef}
          translations={{
            eventToLocation: () => {
              const stage = canvasStageRef.current;
              if (!stage) {
                return null;
              }
              const stageRelativePointerPosition =
                stage.getRelativePointerPosition();

              return {
                x: stageRelativePointerPosition?.x ?? 0,
                y: stageRelativePointerPosition?.y ?? 0,
              };
            },
            locationToDocument: (location: Location) => {
              const stage = canvasStageRef.current;
              const canvasAndCordContainer = canvasAndCordContainerRef.current;
              if (
                !stage ||
                !canvasAndCordContainer ||
                !location ||
                !location.x ||
                !location.y
              ) {
                return null;
              }

              const transform = stage.getTransform();
              const transformedCoords = transform.point({
                x: location.x as number,
                y: location.y as number,
              });

              return {
                viewportX:
                  canvasAndCordContainer.offsetLeft + transformedCoords.x,
                viewportY:
                  canvasAndCordContainer.offsetTop + transformedCoords.y,
                click: false,
              };
            },
          }}
        />
        <div className="canvasButtonGroup">
          <button
            className="controlButton"
            type="button"
            onClick={() => {
              setElementToCreate({
                type: 'Rect',
                height: 200,
                width: 200,
                fill: '#ffffff',
              });
            }}
          >
            <span>{'Add Box'}</span>
          </button>
          <button
            className="controlButton"
            type="button"
            onClick={() => {
              // a bit shitty but it's not easy to edit the text in place
              setElementTypeToCreate((prev) =>
                prev === 'Text' ? undefined : 'Text',
              );
            }}
          >
            <span>{'Add Text'}</span>
          </button>
          <button
            className="controlButton"
            type="button"
            onClick={() => {
              setElementToCreate({
                type: 'Rect',
                width: 3,
                fill: 'black',
                height: 200,
                rotation: 0,
              });
            }}
          >
            <span>{'Add Line'}</span>
          </button>
          <button
            className="controlButton"
            type="button"
            onClick={() => {
              setInThreadCreationMode((prev) => !prev);
              removeThreadIfEmpty(openThread);
            }}
          >
            <CommentIcon />
            <span>{inThreadCreationMode ? 'Cancel' : 'Add Comment'}</span>
          </button>
          <ZoomControls />
        </div>
        <CanvasComments />
        {elementTypeToCreate && (
          <CanvasCreateElementModal
            setElementToCreate={setElementToCreate}
            elementType={elementTypeToCreate}
            closeModal={() => setElementTypeToCreate(undefined)}
          />
        )}
      </div>
    </div>
  );
}
