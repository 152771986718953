import FontFaceObserver from 'fontfaceobserver';
import { useState } from 'react';

export function useFontFaceObserver() {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [error, setError] = useState(false);

  const font = new FontFaceObserver('Virgil');

  font
    .load()
    .then(function () {
      setFontLoaded(true);
      setError(false);
    })
    .catch(function () {
      console.error('Failed to load font');
      setError(true);
    });

  return { fontLoaded, error };
}
