import * as React from 'react';
import { useMemo, useState } from 'react';
import type { KonvaComponentTypes } from 'src/client/components/CanvasElements';
import { useClickOutside } from 'src/client/hooks/useClickOutside';
import { useUpdatingRef } from 'src/client/hooks/useUpdatingRef';

/*
Only used for creating text atm
*/

export function CanvasCreateElementModal({
  elementType,
  setElementToCreate,
  closeModal,
}: {
  elementType: KonvaComponentTypes;
  setElementToCreate: (newVal: Record<string, string | number>) => void;
  closeModal: () => void;
}) {
  const [height, setHeight] = useState(200);
  const [width, setWidth] = useState(200);
  const [fill, setFill] = useState('#ffffff');
  const [text, setText] = useState('');
  const [fontSize, setFontSize] = useState(16);
  const [rotation, setRotation] = useState(0);

  const [modal, setModal] = useState<HTMLDivElement | null>(null);
  const modalRef = useUpdatingRef(modal);

  useClickOutside({
    onMouseDown: (event) => {
      closeModal();
      event.stopPropagation();
    },
    elementRef: modalRef,
    capture: true,
  });

  const inputs = useMemo(() => {
    if (elementType === 'Rect') {
      return (
        <>
          <div>
            <label htmlFor="height">Height:</label>
            <input
              type="text"
              id="height"
              name="height"
              value={height}
              onChange={(e) => setHeight(Number(e.target.value))}
              pattern="[0-9]"
            />
          </div>
          <div>
            <label htmlFor="width">Width:</label>
            <input
              type="text"
              id="width"
              name="width"
              value={width}
              onChange={(e) => setWidth(Number(e.target.value))}
              pattern="[0-9]"
            />
          </div>
          <div>
            <label htmlFor="fill">Fill:</label>
            <input
              type="text"
              id="fill"
              name="fill"
              value={fill}
              onChange={(e) => setFill(e.target.value)}
            />
          </div>
          <button
            type="button"
            onClick={() =>
              setElementToCreate({
                type: 'Rect',
                height,
                width,
                fill,
              })
            }
          >
            Create
          </button>
        </>
      );
    }
    if (elementType === 'Text') {
      return (
        <>
          <div>
            <label htmlFor="text">Text:</label>
            <input
              type="text"
              id="text"
              name="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              onBlur={(e) => setText(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="fontSize">Font size:</label>
            <input
              type="text"
              id="fontSize"
              name="fontSize"
              value={fontSize}
              onChange={(e) => setFontSize(Number(e.target.value))}
              pattern="[0-9]"
            />
            <span>px</span>
          </div>
          <button
            type="button"
            onClick={() => {
              if (text) {
                setElementToCreate({
                  type: 'Text',
                  text,
                  fontSize,
                });
              } else {
                closeModal();
              }
            }}
          >
            Create
          </button>
        </>
      );
    }
    if (elementType === 'Line') {
      return (
        <>
          <div>
            <label htmlFor="length">Length:</label>
            <input
              type="text"
              id="length"
              name="length"
              value={height}
              onChange={(e) => setHeight(Number(e.target.value))}
              pattern="[0-9]"
            />
          </div>
          <div>
            <label htmlFor="Rotation">Rotation:</label>
            <input
              type="text"
              id="Rotation"
              name="Rotation"
              value={rotation}
              onChange={(e) => setRotation(Number(e.target.value))}
            />
          </div>
          <p>Yes sorry this is particularly painful</p>
          <button
            type="button"
            onClick={() => {
              setElementToCreate({
                type: 'Rect',
                width: 3,
                fill: 'black',
                height,
                rotation,
              });
            }}
          >
            Create
          </button>
        </>
      );
    }
  }, [
    closeModal,
    setElementToCreate,
    elementType,
    fill,
    fontSize,
    height,
    rotation,
    text,
    width,
  ]);

  return (
    <div ref={setModal} className={'canvasButtonModal'}>
      {inputs}
    </div>
  );
}
