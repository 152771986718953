import type { KonvaEventObject } from 'konva/lib/Node';
import type {
  Transformer as Transformer2,
  TransformerConfig,
} from 'konva/lib/shapes/Transformer';
import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import type { KonvaNodeComponent } from 'react-konva';
import { Circle, Rect, Text, Star, Line, Transformer } from 'react-konva';
import { CanvasAndCommentsContext } from 'src/client/CanvasAndCommentsContext';

export type KonvaComponentTypes = 'Rect' | 'Circle' | 'Text' | 'Star' | 'Line';
type KonvaComponents =
  | typeof Rect
  | typeof Circle
  | typeof Text
  | typeof Star
  | typeof Line;

const canvasComponents: Record<KonvaComponentTypes, KonvaComponents> = {
  Rect: Rect,
  Circle: Circle,
  Text: Text,
  Star: Star,
  Line: Line,
};

export type CanvasElementProps =
  | (typeof Circle & { type: 'Circle' })
  | (typeof Rect & { type: 'Rect' })
  | (typeof Text & { type: 'Text' })
  | (typeof Star & { type: 'Star' })
  | (typeof Line & { type: 'Line' });

export function CanvasElement({
  isSelected,
  onSelect,
  onChange,
  type,
  ...rest
}: {
  type: KonvaComponentTypes;
  name: string;
}) {
  const {
    openThread,
    setOpenThread,
    removeThreadIfEmpty,
    recomputePinPositions,
  } = useContext(CanvasAndCommentsContext)!;

  const Element: any = canvasComponents[type];

  const shapeRef = useRef<KonvaComponents | undefined>();
  const trRef = useRef<KonvaNodeComponent<Transformer2, TransformerConfig>>();

  useEffect(() => {
    if (isSelected) {
      if (!trRef.current) {
        return;
      }
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  if (Element) {
    return (
      <>
        <Element
          onClick={onSelect}
          onTap={onSelect}
          ref={shapeRef}
          onDragEnd={(e: KonvaEventObject<DragEvent>) => {
            recomputePinPositions();
            removeThreadIfEmpty(openThread);
            setOpenThread(null);
            onChange({
              type,
              ...rest,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onTransformEnd={() => {
            // transformer is changing scale of the node
            // and NOT its width or height
            // but in the store we have only width and height
            // to match the data better we will reset scale on transform end
            const node = shapeRef.current;
            if (!node) {
              return;
            }
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            // we will reset it back
            node.scaleX(1);
            node.scaleY(1);
            onChange({
              ...rest,
              rotation: node.rotation(),
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            });
          }}
          type={type}
          {...rest}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            flipEnabled={false}
            keepRatio={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
}
