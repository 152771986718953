import * as React from 'react';
import { PagePresence } from '@cord-sdk/react';
import { GROUP_ID, THREADS_LOCATION } from 'src/client/consts/consts';

export function CanvasHeader() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#eeeeee',
        padding: '2px 12px',
      }}
    >
      <h2>Mirord: Cord Architecture Whiteboard Overview</h2>
      <PagePresence groupId={GROUP_ID} location={THREADS_LOCATION} />
    </div>
  );
}
