import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from 'src/client/App';
import { SlackRedirect } from 'src/client/components/SlackRedirect';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const router = createBrowserRouter([
  { path: '/slackRedirect', element: <SlackRedirect /> },
  {
    path: '/',
    element: <App />,
  },
]);

root.render(<RouterProvider router={router} />);
