import * as React from 'react';
import { CanvasHeader } from 'src/client/components/CanvasHeader';
import Canvas from 'src/client/components/Canvas';
import { useFontFaceObserver } from 'src/client/hooks/useFontFaceObserver';

export function CanvasWindow() {
  const { fontLoaded, error } = useFontFaceObserver();

  return (
    <div className="canvasWindow">
      <CanvasHeader />
      {(fontLoaded || error) && <Canvas />}
    </div>
  );
}
