export const API_HOST = window.location.host.includes('local')
  ? 'https://local.cord.com:8999'
  : 'https://api.mirord.cord.com';

export const FRONT_END_HOST = window.location.host.includes('local')
  ? 'https://local.cord.com:9001'
  : 'https://mirord.cord.com';

export const GROUP_ID = 'everyone';
export const THREADS_LOCATION = { page: 'canvas' }; // this used to be used for both
export const ELEMENTS_LOCATION = { location: 'elements' };
export const CANVAS_SHAPES_THREAD = 'canvas-shapes-container';
