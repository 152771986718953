import * as React from 'react';
import type { PropsWithChildren, RefObject } from 'react';
import { v4 as uuid } from 'uuid';
import type { Stage } from 'konva/lib/Stage';
import {
  createContext,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { thread } from '@cord-sdk/react';
import {
  updatePinPositionOnStage,
  getPinFromThread,
} from 'src/client/canvasUtils/pin';
import { getStageData } from 'src/client/canvasUtils/common';
import type { OpenThread, Pin } from 'src/client/canvasUtils/common';
import type { KonvaComponentTypes } from 'src/client/components/CanvasElements';
import {
  ELEMENTS_LOCATION,
  GROUP_ID,
  THREADS_LOCATION,
} from 'src/client/consts/consts';

type UpdateElementInput = {
  name: string;
  x: number;
  y: number;
};

export type BasicCanvasElProps = {
  type: KonvaComponentTypes;
  name: string;
};

// Context for storing all thread related information
type CanvasAndCommentsContextType = {
  // Map of all threads on current page, mapping from thread's ID to its
  // calculated pins
  threads: ReadonlyMap<string, Pin>;
  // Adds a thread to the threads map
  addThread: (threadId: string, pinData: Pin) => void;
  // Removes a thread from the threads map
  removeThreadIfEmpty: (openThread: OpenThread) => void;

  // The id of the thread open on this page, and if it's empty (or null if none is open)
  openThread: OpenThread;
  setOpenThread: (arg: OpenThread) => void;

  // True if user can leave threads at the moment
  inThreadCreationMode: boolean;
  setInThreadCreationMode: React.Dispatch<React.SetStateAction<boolean>>;

  // The stage (canvas), and container of the canvas
  canvasStageRef: RefObject<Stage>;
  canvasContainerRef: RefObject<HTMLDivElement>;

  // Panning on Canvas
  isPanningCanvas: boolean;
  setIsPanningCanvas: React.Dispatch<React.SetStateAction<boolean>>;

  // Updates all the thread co-ordinates relative to the canvas
  recomputePinPositions: () => void;

  // Save an updated element's position and other characteristics
  remoteUpdateElementPosition: (input: UpdateElementInput) => void;

  // local update of element's position and other characteristics
  localUpdateElementPosition: (input: UpdateElementInput) => void;

  scale: number;
  zoomAndCenter: ({
    newScale,
    center,
    animate,
    onFinish,
  }: {
    newScale: number;
    center?: { x: number; y: number };
    animate?: boolean;
    onFinish?: () => void;
  }) => void;
  canvasElements: BasicCanvasElProps[];
  addCanvasElement: (canvasElement: BasicCanvasElProps) => void;
  deleteCanvasElement: (canvasElementName: string) => void;
  createNewElement: ({
    type,
    x,
    y,
  }: {
    type: KonvaComponentTypes;
    x: number;
    y: number;
  }) => void;
};
export const CanvasAndCommentsContext = createContext<
  CanvasAndCommentsContextType | undefined
>(undefined);

export function CanvasAndCommentsProvider({ children }: PropsWithChildren) {
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasStageRef = useRef<Stage>(null);

  const [threads, setThreads] = useState<Map<string, Pin>>(new Map());
  const [canvasElementsMap, setCanvasElementsMap] = useState<
    Map<string, BasicCanvasElProps>
  >(new Map());
  const [canvasElementsArray, setCanvasElementsArray] = useState<
    BasicCanvasElProps[]
  >([]);

  useEffect(() => {
    setCanvasElementsArray(
      Array.from(canvasElementsMap, ([_, value]) => value),
    );
  }, [canvasElementsMap]);

  const addThread = useCallback((threadId: string, pinData: Pin) => {
    setThreads((oldThreads) => {
      const newThreads = new Map(oldThreads);
      newThreads.set(threadId, pinData);
      return newThreads;
    });
  }, []);

  const deleteThread = useCallback((threadId: string) => {
    setThreads((oldThreads) => {
      const newThreads = new Map(oldThreads);
      newThreads.delete(threadId);
      return newThreads;
    });
  }, []);

  const removeThreadIfEmpty = useCallback((removeThread: OpenThread) => {
    if (!removeThread || !removeThread.empty) {
      return;
    }

    setThreads((oldThreads) => {
      if (!oldThreads.has(removeThread.threadID)) {
        return oldThreads;
      }
      const newThreads = new Map(oldThreads);
      newThreads.delete(removeThread.threadID);
      return newThreads;
    });
  }, []);

  const [openThread, setOpenThread] = useState<OpenThread>(null);

  const [inThreadCreationMode, setInThreadCreationMode] =
    useState<boolean>(false);

  const [isPanningCanvas, setIsPanningCanvas] = useState<boolean>(false);

  const recomputePinPositions = useCallback(() => {
    if (!canvasStageRef.current) {
      return;
    }
    const stage = canvasStageRef.current;
    setThreads((oldThreads) => {
      const updatedThreads = new Map<string, Pin>();
      Array.from(oldThreads).forEach(([id, oldThread]) => {
        const updatedPin = updatePinPositionOnStage(stage, oldThread);
        if (updatedPin) {
          updatedThreads.set(id, updatedPin);
        }
      });

      return updatedThreads;
    });
  }, []);
  // Fetch existing threads associated with location
  const {
    threads: threadSummaries,
    hasMore,
    loading,
    fetchMore,
  } = thread.useThreads({
    sortBy: 'most_recent_message_timestamp',
    filter: { location: THREADS_LOCATION },
  });
  useEffect(() => {
    if (loading) {
      return;
    }
    if (hasMore) {
      // NOTE: For this demo, fetch all threads on the page.
      void fetchMore(1000);
    }

    if (!canvasStageRef.current) {
      return;
    }
    const stage = canvasStageRef.current;
    threadSummaries
      .filter(
        (t) => !t.resolved && (t.total > 0 || openThread?.threadID === t.id),
      )
      .forEach((t) => {
        const pinData = getPinFromThread(stage, t);
        if (pinData) {
          addThread(t.id, { ...pinData, repliers: t.repliers });
        }
      });

    threadSummaries
      .filter((t) => t.resolved || t.total === 0)
      .forEach((t) => deleteThread(t.id));
  }, [
    addThread,
    deleteThread,
    fetchMore,
    hasMore,
    loading,
    openThread?.threadID,
    threadSummaries,
  ]);

  const addCanvasElement = useCallback((canvasElement: BasicCanvasElProps) => {
    setCanvasElementsMap((oldElements) => {
      const newElements = new Map(oldElements);
      newElements.set(canvasElement.name, canvasElement);
      return newElements;
    });
  }, []);

  const deleteCanvasElement = useCallback(async (canvasElementName: string) => {
    setCanvasElementsMap((oldElements) => {
      // name is going to be the id
      const newElements = new Map(oldElements);
      newElements.delete(canvasElementName);
      return newElements;
    });
    if (!window.CordSDK) {
      console.error('Where did SDK go?');
      return;
    }

    await window.CordSDK.thread
      .updateThread(canvasElementName, { resolved: true })
      .catch((e) => {
        console.error(`Error deleting ${canvasElementName}: ${e}`);
      });
  }, []);

  // Fetch shapes for the canvas (hack: they are stored as metadata in threads
  // at special location)
  const {
    threads: elementThreads,
    hasMore: hasMoreElements,
    loading: loadingElements,
    fetchMore: fetchMoreElements,
  } = thread.useThreads({
    filter: { location: ELEMENTS_LOCATION, resolvedStatus: 'unresolved' },
  });

  useEffect(() => {
    if (loadingElements) {
      return;
    }
    if (hasMoreElements) {
      void fetchMoreElements(100);
    }

    if (!canvasStageRef.current) {
      return;
    }
    elementThreads.forEach((el) => {
      addCanvasElement(el.metadata as BasicCanvasElProps);
    });
  }, [
    addCanvasElement,
    elementThreads,
    fetchMoreElements,
    hasMoreElements,
    loadingElements,
  ]);

  const [scale, setScale] = useState(1);
  const zoomAndCenter = useCallback(
    ({
      newScale,
      center,
      animate,
      onFinish,
    }: {
      newScale: number;
      center?: { x: number; y: number };
      animate?: boolean;
      onFinish?: () => void;
    }) => {
      const stage = canvasStageRef.current;
      if (!stage) {
        return;
      }

      if (!center) {
        const { scale: oldScale, stageX, stageY } = getStageData(stage);

        const centerStage = {
          x: stage.width() / 2,
          y: stage.height() / 2,
        };
        const relatedTo = {
          x: (centerStage.x - stageX) / oldScale,
          y: (centerStage.y - stageY) / oldScale,
        };

        center = {
          x: centerStage.x - relatedTo.x * newScale,
          y: centerStage.y - relatedTo.y * newScale,
        };
      }

      if (animate) {
        canvasStageRef.current.to({
          scaleX: newScale,
          scaleY: newScale,
          ...center,
          duration: 0.2,
          onUpdate: () => {
            recomputePinPositions();
          },
          onFinish,
        });
      } else {
        stage.scale({ x: newScale, y: newScale });
        stage.position(center);
      }
      setScale(newScale);
    },
    [recomputePinPositions],
  );

  const localUpdateElementPosition = useCallback(
    async (newFields: UpdateElementInput) => {
      const oldNode = canvasElementsMap.get(newFields.name);
      const smooshCopy = JSON.parse(JSON.stringify(oldNode));

      for (const field in newFields) {
        smooshCopy[field] = newFields[field];
      }

      // not a deep copy - ok?
      const newMap = new Map(canvasElementsMap);
      newMap.delete(newFields.name);
      newMap.set(newFields.name, smooshCopy);

      setCanvasElementsMap(newMap);
    },
    [canvasElementsMap],
  );

  const remoteUpdateElementPosition = useCallback(
    async (newFields: UpdateElementInput) => {
      if (!window.CordSDK) {
        console.error('Where did SDK go?');
        return;
      }

      // not needed now?
      const currentMetadata = canvasElementsMap.get(newFields.name);

      await window.CordSDK.thread
        .updateThread(newFields.name, {
          metadata: { ...currentMetadata, ...newFields },
        })
        .catch((e) => {
          console.error(`Error updating ${newFields.name}: ${e}`);
        });
    },
    [canvasElementsMap],
  );

  // not really used now
  const defaultProps = useMemo(
    () => ({
      Rect: {
        stroke: 'black',
        strokeWidth: 1,
      },
      Text: { fontFamily: 'Virgil' },
      Line: {
        // points: [100, 200],
        // stroke: 'grey',
        // strokeWidth: 2,
        // lineJoin: 'round',
        // dash: [33, 10],
        // cant store array as metadata! could we do it with really thin rectangles instead
        // or you can stringify array and parse - but the transformer element turned out
        // to not behave properly with Line so i went back to thin rectangles instead
      },
      Circle: {},
      // Star: {
      //   numPoints: 5,
      //   lineJoin: 'round',
      //   fill: '#FFC700',
      //   outerRadius: 20,
      //   innerRadius: 10,
      //   stroke: '#FFFFFF',
      //   strokeWidth: 4,
      // },
    }),
    [],
  );

  const createNewElement = useCallback(
    async (input: {
      type: KonvaComponentTypes;
      x: number;
      y: number;
      // and other stuff
    }) => {
      if (!window.CordSDK) {
        console.error('Where did SDK go?');
        return;
      }

      const stage = canvasStageRef.current;
      if (!stage) {
        console.error('no stage?!');
        return;
      }

      const name = `${input.type}-${uuid()}`;
      const addedProps = defaultProps[input.type];

      const metadata = { name, ...input, ...addedProps };

      addCanvasElement(metadata);

      await window.CordSDK.thread
        .sendMessage(name, {
          content: [
            {
              type: 'p',
              children: [
                {
                  text: 'lol',
                },
              ],
            },
          ],
          createThread: {
            location: ELEMENTS_LOCATION,
            url: window.location.href,
            name,
            metadata,
            groupID: GROUP_ID,
          },
        })
        .catch((e) => {
          console.error('Error creating element', e);
        });
    },
    [addCanvasElement, defaultProps],
  );

  const context = useMemo(
    () => ({
      threads,
      addThread,
      removeThreadIfEmpty,
      openThread,
      setOpenThread,
      inThreadCreationMode,
      setInThreadCreationMode,
      canvasStageRef,
      canvasContainerRef,
      isPanningCanvas,
      setIsPanningCanvas,
      recomputePinPositions,
      zoomAndCenter,
      scale,
      canvasElements: canvasElementsArray,
      addCanvasElement,
      deleteCanvasElement,
      remoteUpdateElementPosition,
      createNewElement,
      localUpdateElementPosition,
    }),
    [
      threads,
      addThread,
      removeThreadIfEmpty,
      openThread,
      inThreadCreationMode,
      isPanningCanvas,
      recomputePinPositions,
      zoomAndCenter,
      scale,
      canvasElementsArray,
      addCanvasElement,
      deleteCanvasElement,
      remoteUpdateElementPosition,
      createNewElement,
      localUpdateElementPosition,
    ],
  );
  return (
    <CanvasAndCommentsContext.Provider value={context}>
      {children}
    </CanvasAndCommentsContext.Provider>
  );
}
